import React from 'react'
import Layout from "../components/Layout";

import Cta from "../components/Cta";

import MacDevSplash from "../images/mac-dev-splash.png"
import MacDevMobile from "../images/mac-dev-mobile.png"
import iPadDesignSplash from "../images/ipad-design-splash.jpg"
import CheckIcon from "../images/bare-check-icon.png"

import ContactBg from "../images/working-together.png"

import "../styles/services.css"

function Services() {
  return (
    <Layout navBackgroundColor="" >

      <div className="services-section-container-image-laptop" style={{ backgroundImage: `url(${MacDevSplash})`}}>
        <div className="mobile-laptop-image" style={{ backgroundImage: `url(${MacDevMobile})`}} />
        <div className="services-content container">
          <div className="content-title">web development</div>
          <div className="content-description">We specialize in building websites. Every site we engineer uses the most stable and modern technologies to ensure the best performance and experience for your customers and users. This also save our clients immeasureable amounts of time, money and headache well into the future because it cuts down on update, fixes  and maintenance costs.</div>
          <div className="content-description">We live and breathe modern internet technologies and have successfully helped businesses create an online destination for their services that convert visitors into customers.</div>

          <div className="content-list">
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Top industry experience from Apple, Blizzard and Facebook</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Lean web tech stack that saves you time, money and headache</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Performant websites, blazingly fast, SEO enhanced</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Cleanly built, simple to maintain and update in the future</div>
          </div>

        </div>
      </div>

      <div className="services-section-container-image-ipad" style={{ backgroundImage: `url(${iPadDesignSplash})`}}>
        <div className="services-content-right container">
        <div className="mobile-ipad-image" style={{ backgroundImage: `url(${iPadDesignSplash})`}} />
          <div className="content-title">design</div>
          <div className="content-description">We are experts in crafting beautiful web apps and designs that appeal to the 18-35 and professional demographics. Our designs aren't just easy on the eyes, we user test everything we deliver, so our clients feel comfortable knowing that their website works as well as it looks.</div>
          <div className="content-description">We are also experts in creating brands and logo designs that will breathe life into your business's online presence, and create a cohesive experience that will impress and engage your customers.</div>

          <div className="content-list">
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/>Designed with both usability and aesthetics in mind</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> We are experts at conveying branding and messaging through design</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Modern designs appeal to a professional and engaged demographic</div>
            <div className="content-list-item"><i className="check-icon" style={{ backgroundImage: `url(${CheckIcon})`}}/> Focused and elegant designs increase sales and conversions</div>
          </div>

        </div>
      </div>

      <hr className="divider" />

      <Cta title="interested in working with us?" buttonText="contact" link="/contact" bg={ContactBg}/>

    </Layout>


  )
}

export default Services;
